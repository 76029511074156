import { groupBy } from "lodash";
import { useCallback } from "react";
import { Button, ButtonGroup, Form, InputGroup, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import type { AccountHoldingResponse } from "../../../api/src/accounts/accounts.service";
import type { TradeRequest } from "../../../api/src/rebalances/rebalances.service";
import FormFieldError from "../components/FormFieldError";
import SymbolField from "../components/SymbolField";
import { formatCurrency } from "../lib/numbers";
import { getTradeSideFactor } from "./lib";

export type RebalanceEditForm = {
  accounts: {
    id: number;
    trades: Omit<TradeRequest, "rebalanceId" | "accountId">[];
  }[];
};

const emptyTrade: TradeRequest = {
  rebalanceId: 0,
  accountId: 0,
  symbol: "",
  price: 0,
  amount: 0,
  side: "buy",
  isMarketTrade: true,
};

/**
 * A multi-item form component for trades on a rebalance. This component
 * displays an arbitrary number of rows for users to view and modify trades.
 */
const TradesEditor = ({
  accountIndex,
  holdings,
  disabled = false,
}: {
  /** The index of the account in the field collection */
  accountIndex: number;
  /** The collection of existing account holdings */
  holdings: AccountHoldingResponse[];
  /** Disable the ability to edit trades if true */
  disabled?: boolean;
}) => {
  const holdingsBySymbol = groupBy(holdings, "security.identifier");

  const {
    register,
    formState: { errors },
    control,
    getValues,
  } = useFormContext<RebalanceEditForm>();

  const {
    fields: tradeFields,
    append: appendTradeField,
    remove: removeTradeField,
  } = useFieldArray({
    name: `accounts.${accountIndex}.trades`,
    control,
    keyName: "_field_id",
  });

  const addTrade = useCallback(() => {
    appendTradeField({ ...emptyTrade });
  }, [appendTradeField]);

  const removeTrade = useCallback(
    (index: number) => () => {
      removeTradeField(index);
    },
    [removeTradeField],
  );

  return (
    <Table className="form-table align-top">
      <thead>
        <tr>
          <th style={{ minWidth: 140 }}>Symbol</th>
          <th className="text-end">Current Shares</th>
          <th style={{ minWidth: 120 }}>Side</th>
          <th style={{ minWidth: 170 }}>Price</th>
          <th style={{ minWidth: 140 }}>Shares</th>
          <th className="text-end">Post-Trade Shares</th>
          <th className="text-end">Trade Value</th>
          <th className="text-end">Post-Trade Value</th>
          {disabled ? null : <th>Add/Delete</th>}
        </tr>
      </thead>
      <tbody>
        {(tradeFields ?? []).map((trade, index) => {
          const currentShares =
            holdingsBySymbol[trade.symbol]?.reduce(
              (sum, holding) => sum + holding.units,
              0,
            ) ?? 0;
          const postTradeShares =
            getTradeSideFactor(trade.side) * trade.amount + currentShares;

          return (
            <tr key={index}>
              <td>
                <SymbolField
                  fieldName={`accounts.${accountIndex}.trades.${index}.symbol`}
                  control={control}
                  register={register}
                  getValues={getValues}
                  disabled={disabled}
                  className={trade.isMarketTrade ? "" : "border-warning"}
                />
                {trade.isMarketTrade ? null : (
                  <Form.Text className="text-danger d-block">
                    This security is not tradeable by custodian and will{" "}
                    <em>not</em> be included in trade execution.
                  </Form.Text>
                )}
                <FormFieldError
                  field={errors.accounts?.[1]?.trades?.[index]?.symbol}
                />
              </td>
              <td className="text-end">{currentShares.toLocaleString()}</td>
              <td>
                <Form.Select
                  {...register(`accounts.${accountIndex}.trades.${index}.side`)}
                  placeholder="Select trade side"
                  disabled={disabled}
                >
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                </Form.Select>
              </td>
              <td>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    {...register(
                      `accounts.${accountIndex}.trades.${index}.price`,
                    )}
                    disabled={disabled}
                  />
                </InputGroup>
              </td>
              <td>
                <Form.Control
                  type="number"
                  {...register(
                    `accounts.${accountIndex}.trades.${index}.amount`,
                  )}
                  min="1"
                  disabled={disabled}
                />
              </td>
              <td className="text-end">{postTradeShares.toLocaleString()}</td>
              <td className="text-end">
                {formatCurrency(
                  -getTradeSideFactor(trade.side) * trade.amount * trade.price,
                  2,
                )}
              </td>
              <td className="text-end">
                {formatCurrency(postTradeShares * trade.price, 2)}
              </td>
              {disabled ? null : (
                <td>
                  <ButtonGroup>
                    <Button onClick={removeTrade(index)} className="fw-bold">
                      –
                    </Button>
                    {index !== tradeFields.length - 1 ? null : (
                      <Button onClick={addTrade} className="fw-bold">
                        +
                      </Button>
                    )}
                  </ButtonGroup>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TradesEditor;
