import { yupResolver } from "@hookform/resolvers/yup";
import { keyBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import type { TradeSide } from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryHouseholdHoldings } from "../clients/household/lib";
import SubmitButton from "../components/SubmitButton";
import RebalanceAccountInfo, {
  RebalanceAccountWithMetrics,
} from "./RebalanceAccountInfo";
import { RebalanceContext } from "./RebalanceInfo";
import { RebalanceEditForm } from "./TradesEditor";
import { useUpdateRebalance } from "./lib";

const schema: yup.ObjectSchema<RebalanceEditForm> = yup.object({
  accounts: yup
    .array()
    .required()
    .of(
      yup.object({
        id: yup.number().required(),
        trades: yup
          .array()
          .required()
          .of(
            yup.object({
              id: yup.number(),
              symbol: yup.string().required(),
              side: yup.string().oneOf<TradeSide>(["buy", "sell"]).required(),
              amount: yup.number().required(),
              price: yup.number().required(),
              isMarketTrade: yup.boolean().required(),
            }),
          ),
      }),
    ),
});

const RebalanceTrades = () => {
  const { rebalance, household } = useContext(RebalanceContext);
  const { rebalanceId } = useParams();

  const form = useForm<RebalanceEditForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = form;

  const { data: dataHoldings, isPending: isPendingHoldings } =
    useQueryHouseholdHoldings(household?.id ?? -1, {
      enabled: typeof household !== "undefined",
    });

  const holdingsByAccount = useMemo(
    () => keyBy(dataHoldings, "id"),
    [dataHoldings],
  );

  useEffect(() => {
    if (typeof rebalance !== "undefined") {
      reset(rebalance);
    }
  }, [rebalance, reset]);

  const saveRebalance = useUpdateRebalance(parseInt(rebalanceId ?? "-1"));

  const notificationContext = useContext(NotificationContext);

  const save = useCallback(
    async (data: RebalanceEditForm) => {
      await saveRebalance.mutateAsync(data);
      notificationContext.pushNotification({
        id: `rebalance-${rebalanceId}`,
        header: "Rebalance Updated",
        body: `Rebalance ${rebalanceId} updated`,
        variant: "success",
      });
    },
    [notificationContext, rebalanceId, saveRebalance],
  );

  return typeof rebalance === "undefined" || isPendingHoldings ? (
    <Loading />
  ) : rebalance.status === "requested" ? (
    <Alert>Rebalance is being calculated...</Alert>
  ) : (
    <Form onSubmit={handleSubmit(save)}>
      <FormProvider {...form}>
        <Row>
          <Col>
            <h4 className="mt-2">Accounts</h4>
            {rebalance.accounts.map((rebalanceAccount, index) => (
              <React.Fragment key={rebalanceAccount.id}>
                <hr />
                <RebalanceAccountInfo
                  rebalanceAccount={
                    rebalanceAccount as RebalanceAccountWithMetrics
                  }
                  accountIndex={index}
                  holdings={
                    holdingsByAccount[rebalanceAccount.id]?.holdings ?? []
                  }
                  rebalanceType={rebalance.type}
                  editable={rebalance.status === "proposed"}
                  householdModelId={household?.assignedModelId}
                />
              </React.Fragment>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
          </Col>
        </Row>
      </FormProvider>
    </Form>
  );
};

export default RebalanceTrades;
