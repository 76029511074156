import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { SecurityGroupRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import SubmitButton from "../components/SubmitButton";
import { getSchemaFieldLabel, secondarySecuritiesSchema } from "../lib/forms";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import SecurityGroupDeleteDialog from "./SecurityGroupDeleteDialog";
import SecurityGroupSecondarySecurities from "./SecurityGroupSecondarySecurities";
import {
  secondarySecurityHelpMessage,
  useCreateSecurityGroup,
  useQuerySecurityGroup,
  useUpdateSecurityGroup,
} from "./lib";
import SymbolField from "../components/SymbolField";

type SecurityGroupForm = Omit<SecurityGroupRequest, "id">;

const schema: yup.ObjectSchema<SecurityGroupForm> = yup.object({
  name: yup.string().required().label("Name"),
  description: yup.string().defined().nullable().label("Description"),
  primarySymbol: yup.string().required().label("Symbol"),
  secondarySecurities: secondarySecuritiesSchema.required(),
});

const SecurityGroupInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
    getValues,
  } = useForm<SecurityGroupForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: secondarySecurityFields,
    append: appendSecondarySecurity,
    remove: removeSecondarySecurity,
    replace: replaceSecondarySecurities,
  } = useFieldArray({
    name: "secondarySecurities",
    control,
    keyName: "_field_id",
  });

  const { securityGroupId: securityGroupIdStr } = useParams();
  const isNew = securityGroupIdStr === "new";
  const securityGroupId = parseInt(securityGroupIdStr ?? "-1");

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataSecurityGroup,
  } = useQuerySecurityGroup(securityGroupId, {
    enabled: !isNew,
  });

  useEffect(() => {
    if (typeof dataSecurityGroup !== "undefined") {
      // Set the form field values once the data is fetched
      const { secondarySecurities, ...securityGroup } = dataSecurityGroup;
      reset(securityGroup);
      replaceSecondarySecurities(secondarySecurities);
    }
  }, [dataSecurityGroup, reset, replaceSecondarySecurities]);

  const { mutateAsync: createSecurityGroup } = useCreateSecurityGroup();
  const { mutateAsync: updateSecurityGroup } =
    useUpdateSecurityGroup(securityGroupId);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: SecurityGroupForm) => {
      try {
        if (isNew) {
          const newId = await createSecurityGroup({
            ...data,
            // Filter out empty secondary security rows
            secondarySecurities: data.secondarySecurities.filter(
              (security) => security.symbol !== "",
            ),
          });
          notificationContext.pushNotification({
            id: `security-group-${newId}`,
            header: "Security Group Created",
            body: `${data.name} security group created`,
            variant: "success",
          });
          navigate(`../${newId}`);
        } else {
          const updatedSecurityGroup = await updateSecurityGroup(data);
          reset(updatedSecurityGroup);
          notificationContext.pushNotification({
            id: `security-group-${securityGroupIdStr}`,
            header: "Security Group Updated",
            body: `${data.name} security group updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save security group", err);
        notificationContext.pushNotification({
          id: `security-group-${securityGroupIdStr}`,
          header: "Failed to Save Security Group",
          body: `Security Group ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      createSecurityGroup,
      isNew,
      navigate,
      notificationContext,
      reset,
      securityGroupIdStr,
      updateSecurityGroup,
    ],
  );

  const watchSecondarySecurityArray = watch("secondarySecurities", []);
  const controlledSecondarySecurityFields = secondarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchSecondarySecurityArray[index],
    }),
  );

  const [showModal, setShowModal] = useState(false);
  const [deleteSecurityGroupId, setDeleteSecurityGroupId] = useState(0);
  const handleDeleteSecurityGroupClick = useCallback(() => {
    setDeleteSecurityGroupId(securityGroupId);
    setShowModal(true);
  }, [securityGroupId]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataSecurityGroup && !isNew ? (
    <FormError message="Security Group not found" />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Security Group Details</h1>
        </Col>
        <Col md="auto">
          <ModelInfoActionButtons
            deleteHandler={handleDeleteSecurityGroupClick}
          />
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={9} md={7}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-name">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.name)}
                  </Form.Label>
                  <Form.Control type="text" {...register("name")} />
                  <FormFieldError field={errors.name} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-description">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.description)}
                  </Form.Label>
                  <Form.Control type="text" {...register("description")} />
                  <FormFieldError field={errors.description} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-primarySymbol">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.primarySymbol)}
                  </Form.Label>
                  <SymbolField
                    fieldName="primarySymbol"
                    register={register}
                    control={control}
                    getValues={getValues}
                  />
                  <FormFieldError field={errors.primarySymbol} />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex">
              <h2 className="me-1">Secondary Securities</h2>
              <HelpTooltip tooltip={secondarySecurityHelpMessage} />
            </div>
            <SecurityGroupSecondarySecurities
              fields={controlledSecondarySecurityFields}
              replace={replaceSecondarySecurities}
              add={appendSecondarySecurity}
              remove={removeSecondarySecurity}
              register={register}
              control={control}
              getValues={getValues}
              errors={errors.secondarySecurities}
            />
            <Row>
              <Col>
                <SubmitButton isSubmitting={isSubmitting} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <SecurityGroupDeleteDialog
        showModal={showModal}
        setShowModal={setShowModal}
        deleteSecurityGroupId={deleteSecurityGroupId}
        setDeleteSecurityGroupId={setDeleteSecurityGroupId}
      />
    </Form>
  );
};

export default SecurityGroupInfo;
