import { Accordion, Alert, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { AccountHoldingResponse } from "../../../api/src/accounts/accounts.service";
import type {
  RebalanceAccount,
  RebalanceMetrics,
  RebalanceType,
} from "../../../api/src/rebalances/rebalances.service";
import Loading from "../Loading";
import { useQueryAccount } from "../clients/account/lib";
import AllocationBar from "../components/AllocationBar/AllocationBar";
import LabelValuePair from "../components/LabelValuePair";
import { displayAccountName, naLabel } from "../lib/display";
import { formatCurrency } from "../lib/numbers";
import { useQueryModel } from "../models/lib";
import TradesEditor from "./TradesEditor";
import { getRiskAllocation } from "./lib";

export type RebalanceAccountWithMetrics = RebalanceAccount &
  Required<RebalanceMetrics>;

const RebalanceAccountInfo = ({
  rebalanceAccount,
  accountIndex,
  holdings,
  rebalanceType,
  householdModelId,
  editable = false,
}: {
  rebalanceAccount: RebalanceAccountWithMetrics;
  accountIndex: number;
  holdings: AccountHoldingResponse[];
  rebalanceType: RebalanceType;
  householdModelId?: number;
  editable: boolean;
}) => {
  const {
    isPending: isPendingAccount,
    isError: isErrorAccount,
    data: account,
  } = useQueryAccount(rebalanceAccount.id);

  const {
    isLoading: isLoadingModelAccount,
    isError: isErrorModelAccount,
    data: dataModelAccount,
  } = useQueryModel(account?.assignedModelId ?? -1, {
    enabled:
      typeof account !== "undefined" &&
      typeof account.assignedModelId !== "undefined",
  });

  const {
    isLoading: isLoadingModelHousehold,
    isError: isErrorModelHousehold,
    data: dataModelHousehold,
  } = useQueryModel(householdModelId ?? -1, {
    enabled:
      typeof account?.assignedModelId === "undefined" &&
      typeof householdModelId !== "undefined",
  });

  const buyTotal = rebalanceAccount.trades
    .filter((trade) => trade.side === "buy")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const sellTotal = rebalanceAccount.trades
    .filter((trade) => trade.side === "sell")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const realizedGainLoss =
    rebalanceAccount.realizedGainLossSt + rebalanceAccount.realizedGainLossLt;

  return isPendingAccount ? (
    <Loading />
  ) : isErrorAccount ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : !account ? (
    <Alert>Account not found</Alert>
  ) : (
    <>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Account Name"
                value={
                  <Link to={`/clients/accounts/${account.id}`}>
                    {displayAccountName(
                      account.displayName,
                      account.displayNumber,
                    )}
                  </Link>
                }
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Account #"
                value={account.displayNumber}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Account Type"
                value={account?.type}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="AUM"
                value={formatCurrency(rebalanceAccount.totalBalance)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Cash"
                value={formatCurrency(rebalanceAccount.cashBalance)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Current Model"
                value={
                  typeof account.assignedModelId === "undefined" &&
                  typeof householdModelId === "undefined" ? (
                    naLabel
                  ) : typeof account.assignedModelId === "undefined" ? (
                    isLoadingModelHousehold ? (
                      <Loading />
                    ) : isErrorModelHousehold ? (
                      <span className="text-danger">Failed to load model</span>
                    ) : typeof dataModelHousehold === "undefined" ? (
                      naLabel
                    ) : (
                      <Link
                        to={`/models/${dataModelHousehold.id}`}
                        className="font-italic"
                      >
                        {dataModelHousehold.name}
                      </Link>
                    )
                  ) : isLoadingModelAccount ? (
                    <Loading />
                  ) : isErrorModelAccount ? (
                    <span className="text-danger">Failed to load model</span>
                  ) : typeof dataModelAccount === "undefined" ? (
                    naLabel
                  ) : (
                    <Link to={`/models/${dataModelAccount.id}`}>
                      {dataModelAccount.name}
                    </Link>
                  )
                }
                plaintext
              />
            </Col>
            {rebalanceType === "raise_cash" ||
            rebalanceType === "spend_cash" ? (
              <Col md={2} className="mb-2">
                <LabelValuePair
                  label={
                    rebalanceType === "raise_cash"
                      ? "Raise Cash Amount"
                      : "Spend Cash Amount"
                  }
                  value={formatCurrency(
                    rebalanceAccount.raiseSpendCashAmount ?? 0,
                  )}
                  plaintext
                />
              </Col>
            ) : null}
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Buy $"
                value={formatCurrency(buyTotal)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Sell $"
                value={formatCurrency(sellTotal)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Net $"
                value={formatCurrency(sellTotal - buyTotal)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Realized Gains"
                value={formatCurrency(realizedGainLoss)}
                plaintext
              />
            </Col>
            <Col md={2} className="mb-2">
              <LabelValuePair
                label="Last Trade Date"
                value={
                  rebalanceAccount.lastTradeDate === null
                    ? naLabel
                    : new Date(
                        rebalanceAccount.lastTradeDate,
                      ).toLocaleDateString()
                }
                plaintext
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <LabelValuePair
                label="Current Allocation"
                value={
                  <AllocationBar
                    data={rebalanceAccount.riskAllocation?.current}
                    converter={getRiskAllocation}
                  />
                }
                plaintext
              />
            </Col>
            <Col>
              <LabelValuePair
                label="Target Allocation"
                value={
                  <AllocationBar
                    data={rebalanceAccount.riskAllocation?.target}
                    converter={getRiskAllocation}
                  />
                }
                plaintext
              />
            </Col>
            <Col>
              <LabelValuePair
                label="Post-Rebalance Allocation"
                value={
                  <AllocationBar
                    data={rebalanceAccount.riskAllocation?.postRebalance}
                    converter={getRiskAllocation}
                  />
                }
                plaintext
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="mb-4">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Trades</Accordion.Header>
              <Accordion.Body>
                <TradesEditor
                  accountIndex={accountIndex}
                  holdings={holdings}
                  disabled={!editable}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default RebalanceAccountInfo;
