import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

const ConditionToggle = <TForm extends FieldValues>({
  name,
  control,
}: {
  name: FieldPath<TForm>;
  control: Control<TForm>;
}) => {
  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  return (
    <ToggleButtonGroup
      type="radio"
      name={fieldName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <ToggleButton value="and" id={`${fieldName}-and`} inputRef={ref}>
        AND
      </ToggleButton>
      <ToggleButton value="or" id={`${fieldName}-or`} inputRef={ref}>
        OR
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ConditionToggle;
