import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  InputGroup,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Control, useController, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type {
  CashSetting,
  CashTargetType,
} from "../../../api/src/cash-settings/lib";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import ActionButton from "../components/ActionButton";
import ConditionToggle from "../components/ConditionToggle";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import {
  cashConditionSchema,
  getSchemaFieldLabel,
  nullableNumberSchema,
} from "../lib/forms";
import CashSettingDeleteDialog from "./CashSettingDeleteDialog";
import {
  useCreateCashSetting,
  useQueryCashSetting,
  useQueryCashSettings,
  useUpdateCashSetting,
} from "./lib";

type CashSettingForm = Omit<CashSetting, "id">;

export const schema: yup.ObjectSchema<CashSettingForm> = yup.object({
  name: yup.string().required().label("Name"),
  cashTargetType: yup
    .string()
    .oneOf<CashTargetType>(["absolute", "percent"])
    .default("percent")
    .required()
    .label("Target Type"),
  cashTargetAbsolute: nullableNumberSchema.min(0).label("Cash Target"),
  cashTargetPercent: nullableNumberSchema
    .min(0)
    .max(100)
    .label("Percent Target"),
  cashTargetMinCondition: cashConditionSchema.label("Minimum Condition"),
  cashTargetMinAbsolute: nullableNumberSchema
    .min(0)
    .label("Minimum Cash Trigger"),
  cashTargetMinPercent: nullableNumberSchema
    .min(0)
    .max(100)
    .label("Minimum Percent Trigger"),
  cashTargetMaxCondition: cashConditionSchema.label("Maximum Condition"),
  cashTargetMaxAbsolute: nullableNumberSchema
    .min(0)
    .label("Maximum Cash Trigger"),
  cashTargetMaxPercent: nullableNumberSchema
    .min(0)
    .max(100)
    .label("Maximum Percent Trigger"),
});

// ToggleButtonGroup/ToggleButton have issues with refs from react-hook-form
// having a race condition. If the initial form render is too quick, the
// ToggleButton inputs display as unselected, even if an initial value is passed
// to the form in the 'reset' function. Use controlled form components to work
// around this issue.

const AbsolutePercentToggle = ({
  name,
  control,
}: {
  name: keyof CashSettingForm;
  control: Control<CashSettingForm>;
}) => {
  const {
    field: { onChange, onBlur, value, ref, name: fieldName },
  } = useController({
    name,
    control,
  });

  return (
    <ToggleButtonGroup
      type="radio"
      name={fieldName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <ToggleButton value="percent" id={`${fieldName}-percent`} inputRef={ref}>
        %
      </ToggleButton>
      <ToggleButton
        value="absolute"
        id={`${fieldName}-absolute`}
        inputRef={ref}
      >
        $
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const CashSettingInfo = () => {
  const {
    register,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<CashSettingForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      cashTargetType: "percent",
      cashTargetAbsolute: null,
      cashTargetPercent: null,
      cashTargetMinCondition: "and",
      cashTargetMaxCondition: "and",
    },
  });

  const { cashSettingId: cashSettingIdStr } = useParams();
  const isNew = cashSettingIdStr === "new";
  const cashSettingId =
    typeof cashSettingIdStr === "undefined" || isNew
      ? undefined
      : parseInt(cashSettingIdStr);

  const navigate = useNavigate();

  const {
    isPending: isPendingCashSetting,
    isError: isErrorCashSetting,
    data: dataCashSetting,
  } = useQueryCashSetting(
    typeof cashSettingId === "undefined" ? -1 : cashSettingId,
    {
      enabled: !isNew && typeof cashSettingId !== "undefined",
    },
  );

  useEffect(() => {
    if (typeof dataCashSetting !== "undefined") {
      reset(dataCashSetting);
    }
  }, [dataCashSetting, reset]);

  const createCashSetting = useCreateCashSetting();
  const updateCashSetting = useUpdateCashSetting(cashSettingId ?? -1);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: CashSettingForm) => {
      try {
        if (isNew) {
          const newCashSettingId = await createCashSetting.mutateAsync(data);
          notificationContext.pushNotification({
            id: `cash-setting-${newCashSettingId}`,
            header: "Cash Setting Created",
            body: `${data.name} created`,
            variant: "success",
          });
          navigate(`../${newCashSettingId}`);
        } else if (typeof cashSettingId !== "undefined") {
          await updateCashSetting.mutateAsync(data);
          notificationContext.pushNotification({
            id: `cash-setting-${cashSettingId}`,
            header: "Cash Settings Updated",
            body: `${data.name} updated`,
            variant: "success",
          });
        }
      } catch (ex) {
        console.error("Failed to save cash settings", ex);
        notificationContext.pushNotification({
          id: `cash-setting-${cashSettingId}`,
          header: "Failed to Save Cash Settings",
          body: `Cash Setting ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      cashSettingId,
      createCashSetting,
      isNew,
      navigate,
      notificationContext,
      updateCashSetting,
    ],
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedCashSettingId, setCashSettingId] = useState<number | null>(
    null,
  );
  const handleDeleteClick = useCallback(() => {
    if (typeof cashSettingId !== "undefined") {
      setShowModal(true);
    }
  }, [cashSettingId]);

  const cashTargetType = watch("cashTargetType");

  const {
    isPending: isPendingCashSettings,
    isError: isErrorCashSettings,
    data: dataCashSettings,
  } = useQueryCashSettings({ enabled: !isNew });

  const otherCashSettings = (dataCashSettings?.data || []).filter(
    (cashSetting) =>
      typeof cashSettingId === "undefined" || cashSetting.id !== cashSettingId,
  );

  const cashSettingOptions = isPendingCashSettings
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorCashSettings
      ? [
          <option key={null} value="">
            There was an error loading cash settings
          </option>,
        ]
      : [
          <option key="" value="">
            None
          </option>,
          ...otherCashSettings
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((cashSetting) => (
              <option
                key={cashSetting.id}
                value={cashSetting.id}
                hidden={cashSetting.id === cashSettingId}
              >
                {cashSetting.name}
              </option>
            )),
        ];

  return !isNew && isPendingCashSetting ? (
    <Loading />
  ) : isErrorCashSetting ? (
    <FormError message="Failed to load cash settings" />
  ) : !isNew && !dataCashSetting ? (
    <FormError message="Cash settings not found" />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Cash Setting Details</h1>
        </Col>
        <Col md="auto">
          <ButtonToolbar className="mb-3">
            {isNew ? null : (
              <ButtonGroup className="me-4">
                <ActionButton
                  type="button"
                  label="Delete"
                  icon="/icons/trash.svg"
                  onClick={handleDeleteClick}
                />
              </ButtonGroup>
            )}
            <ButtonGroup>
              <ActionButton
                as={Link}
                to="/cash/settings"
                label="Exit"
                icon="/icons/exit.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Content>
        <Row>
          <Col xxl={4} xl={4} md={6} className="mb-3">
            <Form.Label>{getSchemaFieldLabel(schema.fields.name)}</Form.Label>
            <Form.Control type="text" {...register("name")} />
            <FormFieldError field={errors.name} />
          </Col>
          <Col xxl={3} xl={4} md={6} className="mb-3">
            <Form.Label>
              {getSchemaFieldLabel(schema.fields.cashTargetAbsolute)}
            </Form.Label>
            <InputGroup>
              {cashTargetType !== "absolute" ? null : (
                <>
                  <Form.Control
                    type="number"
                    min={0}
                    step={0.01}
                    {...register("cashTargetAbsolute")}
                  />
                  <FormFieldError field={errors.cashTargetAbsolute} />
                </>
              )}
              {cashTargetType !== "percent" ? null : (
                <>
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    step={0.01}
                    {...register("cashTargetPercent")}
                  />
                  <FormFieldError field={errors.cashTargetPercent} />
                </>
              )}
              <AbsolutePercentToggle name="cashTargetType" control={control} />
            </InputGroup>
          </Col>
          <Col xl={6} className="mb-3">
            <Form.Label>
              {getSchemaFieldLabel(schema.fields.cashTargetMinAbsolute)}
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min={0}
                step={0.01}
                {...register("cashTargetMinAbsolute")}
              />
              <ConditionToggle
                name="cashTargetMinCondition"
                control={control}
              />
              <Form.Control
                type="number"
                min={0}
                max={100}
                step={0.01}
                {...register("cashTargetMinPercent")}
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
            <FormFieldError field={errors.cashTargetMinCondition} />
            <FormFieldError field={errors.cashTargetMinAbsolute} />
            <FormFieldError field={errors.cashTargetMinPercent} />
          </Col>
          <Col xl={6} className="mb-3">
            <Form.Label>
              {getSchemaFieldLabel(schema.fields.cashTargetMaxAbsolute)}
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min={0}
                step={0.01}
                {...register("cashTargetMaxAbsolute")}
              />
              <ConditionToggle
                name="cashTargetMaxCondition"
                control={control}
              />
              <Form.Control
                type="number"
                min={0}
                max={100}
                step={0.01}
                {...register("cashTargetMaxPercent")}
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
            <FormFieldError field={errors.cashTargetMaxCondition} />
            <FormFieldError field={errors.cashTargetMaxAbsolute} />
            <FormFieldError field={errors.cashTargetMaxPercent} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} />
            <CashSettingDeleteDialog
              showModal={showModal}
              setShowModal={setShowModal}
              deleteCashSettingId={
                typeof cashSettingId === "undefined" ? null : cashSettingId
              }
              setDeleteCashSettingId={() => null}
              selectedCashSettingId={selectedCashSettingId}
              setCashSettingId={setCashSettingId}
              cashSettingOptions={cashSettingOptions}
              title="delete-cash-setting"
            />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

export default CashSettingInfo;
